import React, { useMemo } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import Layout from "@w3f/gatsby-theme-w3f/src/components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import { sortCollectionItems } from "utils/sort-collection-items";
import Container from "layouts/Container";
import Hero from "components/Hero";
import ProjectGrid from "../components/ProjectGrid";
import Cta from "components/Cta";


const Projects = ({ data: { allProjects, page, footerImage } }) => {
  const { t } = useTranslation();
  const { projects, seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  const sortedItems = useMemo(() => sortCollectionItems(allProjects.nodes, projects), [allProjects, projects]);

  return (
    <Layout footerImage={footerImage}>
      <SEO {...meta} />
      <Container className="container--t-margin-sm container--b-margin-md" noOverflow={false}>
        <Hero
          title={t("Projects")}
          content={
            <p>
              We support Web 3.0 teams and open-source projects through funding, advocacy, research and collaborations.
            </p>
          }
        />
      </Container>
      <ProjectGrid projects={sortedItems} />
      <Container className="container--v-margin">
        <Cta
          title="Interested in joining the team researching, supporting and launching these projects?"
          link={{
            to: "https://web3.bamboohr.com/jobs/",
            title: "Web3 Foundation Careers",
          }}
        />
      </Container>
    </Layout>
  );
};

export default Projects;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(fileAbsolutePath: { regex: "//pages/projects.md/" }) {
      frontmatter {
        projects
        seo {
          ...Seo
        }
      }
    }
    allProjects: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $language } }, fileAbsolutePath: { regex: "//(projects)//" } }
    ) {
      nodes {
        html
        frontmatter {
          id
          image {
            childImageSharp {
              gatsbyImageData(height: 265, layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
          title
          description
          link {
            title
            url
          }
        }
        fields {
          langKey
          slug
        }
      }
    }
    footerImage: file(name: { eq: "projects-large" }) {
      ...BgImage
    }
  }
`;
